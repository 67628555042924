.snowFlake {
    position: fixed;
    top: -16px;
}

@keyframes fall {
    from {}

    to {
        transform: translateY(100vh);
        opacity: 0;
    }
}